import { useState, useEffect } from "react";
import InputAutoWidth from "../../../components/Inputs/AutoWidth"
import { notify } from "../../../utils/notification";

export default function NewAudience({ loja, clientes, active, closeModal, refreshList, saveAudience, setNotification }) {

  const [title, setTitle] = useState('Nova Audiência')
  const [selectedClients, setSelectedClients] = useState([])
  const [audienceClientsType, setAudienceClientsType] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredClients, setFilteredClients] = useState([])

  useEffect(() => {
    if (clientes && clientes.length > 0) {
      if (searchTerm.trim() === '') {
        setFilteredClients(clientes)
      } else {
        const searchTermLower = searchTerm.toLowerCase()
        const filtered = clientes.filter(client => 
          (client.name && client.name.toLowerCase().includes(searchTermLower)) || 
          (client.email && client.email.toLowerCase().includes(searchTermLower))
        )
        setFilteredClients(filtered)
      }
    }
  }, [searchTerm, clientes])

  function onChangeManual(client) {
    const verifyClient = selectedClients.filter(fiClient => fiClient._id === client._id).length;

    var newArray = [...selectedClients];
    if(verifyClient === 0) {
      newArray.push(client)
    } else {
      newArray = newArray.filter(fiClient => fiClient._id !== client._id)
    }

    setSelectedClients(newArray)

    return;
  }

  function handleAdd() {
    saveAudience({ title, leads: audienceClientsType === "manual" ? selectedClients : [], leadsType: audienceClientsType }, loja, (audience) => {
      refreshList();
      clearStates();
      return notify("Audiência criada com sucesso!", setNotification);
    })
    return;
  }

  function clearStates() {
    setTitle('Nova Audiência')
    setSelectedClients([])
    setAudienceClientsType('')
    setSearchTerm('')
    closeModal()
  }

  return (
    <div className={`modalAudience${active ? ' active' : ''}`}>
      <div className="content">
        <div className="close" onClick={() => clearStates()}>
          <i className="fas fa-times"></i>
        </div>
        <InputAutoWidth initialTitle={title} buttonTitle="Ok" callback={(_title) => setTitle(_title)} />

        <div className="list-items">
          <p>Selecione os <span>clientes que participarão</span> desta lista:</p>
          <div className="item" onClick={() => setAudienceClientsType("leads")}>
            <div className={`input-radio${audienceClientsType === "leads" ? ' active' : ''}`}></div>
            <label>Leads que ainda não compraram <div className="new">Novo</div></label>
          </div>
          <div className="item" onClick={() => setAudienceClientsType("allclients")}>
            <div className={`input-radio${audienceClientsType === "allclients" ? ' active' : ''}`}></div>
            <label>Todos os clientes da loja</label>
          </div>
          <div className="item" onClick={() => setAudienceClientsType("manual")}>
            <div className={`input-radio${audienceClientsType === "manual" ? ' active' : ''}`}></div>
            <label>Selecionar manualmente</label>
          </div>
        </div>

        { audienceClientsType === "manual" && (
          <div className="selectLeads">
            <div className="search-container">
              <input 
                type="text" 
                placeholder="Buscar por nome ou email..." 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
              <i className="fas fa-search search-icon"></i>
              {searchTerm && (
                <i 
                  className="fas fa-times clear-icon" 
                  onClick={() => setSearchTerm('')}
                ></i>
              )}
            </div>
            
            <div className="clients-count">
              {filteredClients.length} clientes encontrados
              {selectedClients.length > 0 && ` (${selectedClients.length} selecionados)`}
            </div>
            
            <div className="clients-list">
              { filteredClients.map((client, index) => {
                const isSelected = selectedClients.some(c => c._id === client._id);
                return (
                  <div className={`item${isSelected ? ' selected' : ''}`} key={index}>
                    <input 
                      type="checkbox" 
                      id={`lead[${index}]`} 
                      onChange={() => onChangeManual(client)} 
                      checked={isSelected}
                    />
                    <label htmlFor={`lead[${index}]`}>{client.name} - {client.email}</label>
                  </div>
                );
              })}
              
              {filteredClients.length === 0 && (
                <div className="no-results">
                  Nenhum cliente encontrado com o termo "{searchTerm}"
                </div>
              )}
            </div>
          </div>
        )}

        <button onClick={handleAdd}>Adicionar</button>
      </div>
    </div>
  )
}