import { useState, useEffect } from 'react';

import { connect } from 'react-redux'
import * as actions from '../../actions/email-marketing'

import Campaigns from './campaigns';
import Audiences from './audiences';
import Templates from './templates';
import { verifyAdmin } from '../../utils/verify-admin'
import Loading from '../../components/Loading';

function EmailMarketing({ history, admin, clientes, leads, campaigns, templates, listCampaigns, listTemplates, saveTemplate, removeTemplate, audiences, listAudiences, listClientsAudience, listLeadsAudience, saveAudience, removeAudience, createCampaign }){

  const [section, setSection] = useState("campaigns")
  const [loading, setLoading] = useState({
    templates: false,
    audiences: false,
    campaigns: false,
    clients: false,
    leads: false
  })

  function refreshCampaigns() {
    setLoading(prev => ({...prev, campaigns: true}))
    listCampaigns(admin.loja, () => {
      setLoading(prev => ({...prev, campaigns: false}))
    })
  }

  function refreshTemplates(limit = 5) {
    setLoading(prev => ({...prev, templates: true}))
    listTemplates(admin.loja, limit, () => {
      setLoading(prev => ({...prev, templates: false}))
    })
  }

  function refreshAudiences() {
    setLoading(prev => ({...prev, audiences: true}))
    listAudiences(admin.loja, () => {
      setLoading(prev => ({...prev, audiences: false}))
    })
  }

  function refreshClients() {
    setLoading(prev => ({...prev, clients: true}))
    listClientsAudience(admin.loja, () => {
      setLoading(prev => ({...prev, clients: false}))
    })
  }

  function refreshLeads() {
    setLoading(prev => ({...prev, leads: true}))
    listLeadsAudience(admin.loja, () => {
      setLoading(prev => ({...prev, leads: false}))
    })
  }

  // Handle section change
  const handleSectionChange = (newSection) => {
    setSection(newSection);
    
    // Reset loading states when changing sections
    setLoading({
      templates: false,
      audiences: false,
      campaigns: false,
      clients: false,
      leads: false
    });
  };

  // Staggered loading to prioritize the current section's data
  useEffect(() => {
    if (!admin) return;

    // Load data for the current section first
    if (section === "campaigns") {
      if (!campaigns) refreshCampaigns();
      if (!templates) refreshTemplates();
      
      // Load other data after a delay
      setTimeout(() => {
        if (!audiences) refreshAudiences();
        if (!clientes) refreshClients();
        if (!leads) refreshLeads();
      }, 1000);
    } 
    else if (section === "templates") {
      if (!templates) refreshTemplates();
      
      // Load other data after a delay
      setTimeout(() => {
        if (!campaigns) refreshCampaigns();
        if (!audiences) refreshAudiences();
        if (!clientes) refreshClients();
        if (!leads) refreshLeads();
      }, 1000);
    }
    else if (section === "audiences") {
      if (!audiences) refreshAudiences();
      if (!clientes) refreshClients();
      if (!leads) refreshLeads();
      
      // Load other data after a delay
      setTimeout(() => {
        if (!campaigns) refreshCampaigns();
        if (!templates) refreshTemplates();
      }, 1000);
    }
  }, [section, admin]);

  // Force reset loading state if data is available but loading state is stuck
  useEffect(() => {
    if (audiences && loading.audiences) {
      setLoading(prev => ({...prev, audiences: false}));
    }
    if (clientes && loading.clients) {
      setLoading(prev => ({...prev, clients: false}));
    }
    if (leads && loading.leads) {
      setLoading(prev => ({...prev, leads: false}));
    }
    if (campaigns && loading.campaigns) {
      setLoading(prev => ({...prev, campaigns: false}));
    }
    if (templates && loading.templates) {
      setLoading(prev => ({...prev, templates: false}));
    }
  }, [audiences, clientes, leads, campaigns, templates]);

  // Add a timeout to prevent infinite loading
  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      // If any section is still loading after 10 seconds, force reset the loading state
      setLoading({
        templates: false,
        audiences: false,
        campaigns: false,
        clients: false,
        leads: false
      });
    }, 10000);

    return () => clearTimeout(loadingTimeout);
  }, [loading]);

  if(!admin || (admin && !verifyAdmin(admin, 'owner'))) {
    return null;
  }

  // Debug information
  console.log("Current section:", section);
  console.log("Loading states:", loading);
  console.log("Data available:", {
    audiences: !!audiences,
    clientes: !!clientes,
    leads: !!leads,
    campaigns: !!campaigns,
    templates: !!templates
  });

  return (
    <div className="EmailMarketing">
      <div className="container-centered">
        <div className="wrapper">
          <h2><i className="fas fa-envelope-open-text"></i> E-mail Marketing</h2>
          
          <div className="menu">
            <button 
              onClick={() => handleSectionChange("campaigns")} 
              className={section === "campaigns" ? "active" : ""}
            >
              <i className="fas fa-paper-plane"></i> Campanhas
            </button>
            <button 
              onClick={() => handleSectionChange("audiences")} 
              className={section === "audiences" ? "active" : ""}
            >
              <i className="fas fa-users"></i> Audiências
            </button>
            <button 
              onClick={() => handleSectionChange("templates")} 
              className={section === "templates" ? "active" : ""}
            >
              <i className="fas fa-paint-roller"></i> Templates de E-mails
            </button>
          </div>

          {section === "campaigns" && (
            loading.campaigns ? <div className="section-loading"><Loading /></div> : 
            <Campaigns 
              admin={admin} 
              campaigns={campaigns} 
              refreshList={refreshCampaigns} 
              templates={templates} 
              audiences={audiences} 
              clientsLength={clientes ? clientes.length : 0} 
              leadsLength={leads ? leads.length : 0} 
              createCampaign={createCampaign} 
              listTemplates={listTemplates} 
            />
          )}
          
          {section === "audiences" && (
            (loading.audiences || loading.clients || loading.leads) ? <div className="section-loading"><Loading /></div> : 
            <Audiences 
              admin={admin} 
              audiences={audiences} 
              refreshList={refreshAudiences} 
              clientes={clientes} 
              leads={leads} 
              listClientsAudience={listClientsAudience} 
              listLeadsAudience={listLeadsAudience} 
              saveAudience={saveAudience} 
              removeAudience={removeAudience} 
            />
          )}
          
          {section === "templates" && (
            loading.templates ? <div className="section-loading"><Loading /></div> : 
            <Templates 
              admin={admin} 
              templates={templates} 
              refreshList={refreshTemplates} 
              saveTemplate={saveTemplate} 
              removeTemplate={removeTemplate} 
            />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  admin: state.auth.usuario,
  campaigns: state.emarketing.campaigns,
  templates: state.emarketing.templates,
  clientes: state.emarketing.clientes,
  leads: state.emarketing.leads,
  audiences: state.emarketing.audiences
});

export default connect(mapStateToProps, actions)(EmailMarketing);