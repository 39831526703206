import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/pedidos';

import AlertGeral from '../../components/Alert/Geral';
import DynamicSelector from '../../components/Lista/DynamicSelector';

function StatusRecods({ records, admin, order, payment, setNovoStatusPagamento, setNovoStatusEntrega, refreshList }) {
	const [warning, setWarning] = useState(null);

	function newStatePayment({ status, justifyText }) {
		setWarning(null);
		if(!status) return setWarning({ aviso: { status: false, msg: "Preencha o campo para enviar um novo status" }});
		
		setNovoStatusPagamento({ status, justifyText: justifyText || null }, order.pagamento._id || order.pagamento, order._id, admin.loja, (error) => {
			if(error) return setWarning({ status: false, msg: error.message });
			else refreshList();
		});
	}

  function newStateDelivery({ status, justifyText }) {
		setWarning(null)
		if(!status) return setWarning({ aviso: { status: false, msg: "Preencha o campo para enviar um novo status" }});
		
		setNovoStatusEntrega({ status, justifyText: justifyText || null }, order.entrega._id, order._id, admin.loja, (error) => {
			if(error) return setWarning({ status: false, msg: error.message })
			else refreshList();
		});
	}

	const TransactionInfo = ({ payment }) => {

		if (!payment || !payment?.card || payment.card.length<=0) {
			return <div>Nenhuma transação encontrada.</div>;
		}

		const statusMap = {
			paid: "Pago",
			authorized: "Aprovada",
			not_authorized: "Recusada",
			failed: "Falha",
			pending: "Pendente",
			canceled: "Cancelada",
			refunded: "Estornada",
			processing: "Em processamento",
		};


		const { last_transaction, customer, status } = payment.card[0];
		// return (<div></div>)
		if (payment.card[0].payment_method==='credit_card') {
			const { card, acquirer_message, updated_at } = last_transaction;
			return (
				<div>
					<h3>Detalhes da Transação {card.brand}</h3>
					<p><strong>Última Atualização:</strong> {new Date(updated_at).toLocaleString()}</p>
					<p><strong>Nome do Titular:</strong> {card.holder_name}</p>
					<p><strong>Documento(CPF):</strong> {customer.document}</p>
					<p><strong>Últimos 4 dígitos do cartão:</strong> {card.last_four_digits}</p>
					<p><strong>Endereço do cartão:</strong> {card.billing_address.line_1}, {card.billing_address.city}-{card.billing_address.state}, CEP: {card.billing_address.zip_code}</p>
					<p><strong>Status da Transação:</strong> {statusMap[status] || status}</p>
					{status !== "authorized" && (
						<p><strong>Motivo da Recusa:</strong> {acquirer_message}</p>
					)}
				</div>
			);
		}

		if (payment.card[0].payment_method==='pix') {
			const { updated_at } = last_transaction;
			return (
				<div>
					<h3>Detalhes da Transação PIX</h3>
					<p><strong>Última Atualização:</strong> {new Date(updated_at).toLocaleString()}</p>
					<p><strong>Status da Transação:</strong> {statusMap[status] || status}</p>
					<p><strong>QR Code:</strong> {last_transaction.qr_code}</p>
					<p><strong>QR Code Url:</strong> {last_transaction.qr_code_url}</p>
					<img src={last_transaction.qr_code_url} alt="QR Code for Payment" style={{ width: "200px", height: "200px" }} />
				</div>
			);
		}

		if (payment.card[0]?.purchase_units[0].soft_descriptor) {
			const purchase = payment.card[0]?.purchase_units[0];
			const payer = payment.card[0]?.payer;

			return (
				<div>
					<h3>Detalhes da Transação (PayPal)</h3>
					<p><strong>Última Atualização:</strong> {new Date(payment.card[0]?.update_time).toLocaleString()}</p>
					<p><strong>Nome do Pagador:</strong> {payer.name.given_name} {payer.name.surname}</p>
					<p><strong>Email do Pagador:</strong> {payer.email_address}</p>
					<p><strong>País do Pagador:</strong> {payer.address.country_code}</p>
					<p><strong>Valor da Transação:</strong> {purchase.amount.value} {purchase.amount.currency_code}</p>
					<p><strong>Status da Transação:</strong> {statusMap[payment.status] || payment.status}</p>
				</div>
			);
		}

		return (<div></div>)
	};


	if(!records) {
		return <div></div>;
	}else {
		return (
			<>
				<div className="status-container">
					<h2>Status de Pagamento</h2>
					<AlertGeral aviso={warning}/>
					<DynamicSelector available={order.cancelado} type="Pagamento" records={records.pagamento}
									 onAdd={({status, justifyText}) => newStatePayment({status, justifyText})}/>
					<TransactionInfo payment={payment} />
				</div>
				<div className="status-container">
					<h2>Status de Entrega</h2>
					<AlertGeral aviso={warning}/>
					<DynamicSelector available={order.cancelado} type="Entrega" records={records.entrega}
									 onAdd={({status, justifyText}) => newStateDelivery({status, justifyText})}/>
				</div>
			</>
		)
	}
}

export default connect(null, actions)(StatusRecods);