import { getHeaders } from './localStorage';
import axios from 'axios';
import { api, versao } from '../config';
import errorHandling from './errorHandling';
import { GET_TEMPLATES, GET_CLIENTS_AUDIENCE, GET_LEADS_AUDIENCE, GET_AUDIENCES, GET_CAMPAIGNS } from './types';

// Cache for API responses
const apiCache = {
	templates: {},
	campaigns: {},
	audiences: {},
	clients: {},
	leads: {}
};

// Cache timeout in milliseconds (5 minutes)
const CACHE_TIMEOUT = 5 * 60 * 1000;

export const listTemplates = (loja, limit = 10, cb) => {
	return function(dispatch){
		// Check if we have a valid cached response
		const cacheKey = `${loja}_${limit}`;
		const cachedData = apiCache.templates[cacheKey];
		const now = new Date().getTime();
		
		if (cachedData && (now - cachedData.timestamp < CACHE_TIMEOUT)) {
			dispatch({ type: GET_TEMPLATES, payload: cachedData.data });
			if (cb) return cb(cachedData.data);
			return;
		}
		
		// If no cache or cache expired, make the API call
		axios.get(`${api}/${versao}/api/email-marketing/templates?loja=${loja}&limit=${limit}`, getHeaders())
		.then(response => {
			// Cache the response
			apiCache.templates[cacheKey] = {
				data: response.data,
				timestamp: now
			};
			
			dispatch({ type: GET_TEMPLATES, payload: response.data });
			if (cb) return cb(response.data);
		})
		.catch(error => {
			errorHandling(error);
			if (cb) cb(null);
		});
	}
}

export const saveTemplate = ({ _id, title, design, html }, loja, cb) => {
	return function(){
		axios.put(`${api}/${versao}/api/email-marketing/templates/save?loja=${loja}`, { _id, title, design, html }, getHeaders())
		.then(response => cb(response.data.template))
		.catch(errorHandling);
	}
}

export const removeTemplate = (templateId, loja, cb) => {
	return function(){
		axios.delete(`${api}/${versao}/api/email-marketing/templates/${templateId}?loja=${loja}`, getHeaders())
		.then(response => {
			return cb({ success: true })
		})
		.catch(error => {
			return cb({ success: false })
		})
	}
}

export const listClientsAudience = (loja, cb) => {
	return function(dispatch){
		axios.get(`${api}/${versao}/api/clientes/all?loja=${loja}`, getHeaders())
		.then(async response => {
			var clients = [];
			await response.data?.clientes?.forEach(client => {
				if(!client?.email || clients?.filter(_client => _client.email === client?.email)[0]) return null;
				clients.push(client)
			})

			dispatch({ type: GET_CLIENTS_AUDIENCE, payload: { clientes: clients } })
			return cb(response.data)
		})
		.catch(errorHandling);
	}
}

export const listLeadsAudience = (loja, cb) => {
	return function(dispatch){
		axios.get(`${api}/${versao}/api/leads/all?loja=${loja}`, getHeaders())
		.then(response => {
			dispatch({ type: GET_LEADS_AUDIENCE, payload: response.data })
			return cb(response.data)
		})
		.catch(errorHandling);
	}
}

export const saveAudience = ({ _id, title, leads, leadsType }, loja, cb) => {
	return function(){
		axios.put(`${api}/${versao}/api/email-marketing/audiences/save?loja=${loja}`, { _id, title, leads, leadsType }, getHeaders())
		.then(response => cb(response.data.audience))
		.catch(errorHandling);
	}
}

export const listAudiences = (loja, cb) => {
	return function(dispatch){
		const cachedData = apiCache.audiences[loja];
		const now = new Date().getTime();
		
		if (cachedData && (now - cachedData.timestamp < CACHE_TIMEOUT)) {
			dispatch({ type: GET_AUDIENCES, payload: cachedData.data });
			if (cb) return cb(cachedData.data);
			return;
		}
		
		axios.get(`${api}/${versao}/api/email-marketing/audiences?loja=${loja}`, getHeaders())
		.then(response => {
			apiCache.audiences[loja] = {
				data: response.data,
				timestamp: now
			};
			
			dispatch({ type: GET_AUDIENCES, payload: response.data });
			if (cb) return cb(response.data);
		})
		.catch(error => {
			errorHandling(error);
			if (cb) cb(null);
		});
	}
}

export const removeAudience = (_id, loja, cb) => {
	return function(){
		axios.delete(`${api}/${versao}/api/email-marketing/audience/${_id}?loja=${loja}`, getHeaders())
		.then(response => {
			return cb({ success: true })
		})
		.catch(error => {
			return cb({ success: false })
		})
	}
}

export const createCampaign = (data, loja, callback, errorCallback) => {
	return function(dispatch){
		// Show that we're starting the request
		console.log("Starting campaign creation...");
		
		axios.post(`${api}/${versao}/api/email-marketing/campaigns?loja=${loja}`, data, getHeaders())
		.then(response => {
			console.log("Campaign created successfully");
			
			// Update the cache with the new campaign
			const now = new Date().getTime();
			if (apiCache.campaigns[loja]) {
				// If we have cached campaigns, add the new one to the cache
				const updatedCampaigns = {
					...apiCache.campaigns[loja].data,
					campaigns: [response.data.campaign, ...apiCache.campaigns[loja].data.campaigns]
				};
				
				apiCache.campaigns[loja] = {
					data: updatedCampaigns,
					timestamp: now
				};
				
				// Update Redux store
				dispatch({ type: GET_CAMPAIGNS, payload: updatedCampaigns });
			}
			
			if(callback) callback(response.data);
		})
		.catch(error => {
			console.error("Campaign creation error:", error);
			if(errorCallback) errorCallback(error);
			else errorHandling(error);
		});
	}
}

export const listCampaigns = (loja, cb) => {
	return function(dispatch){
		// Check cache
		const cachedData = apiCache.campaigns[loja];
		const now = new Date().getTime();
		
		if (cachedData && (now - cachedData.timestamp < CACHE_TIMEOUT)) {
			dispatch({ type: GET_CAMPAIGNS, payload: cachedData.data });
			if (cb) return cb(cachedData.data);
			return;
		}
		
		axios.get(`${api}/${versao}/api/email-marketing/campaigns?loja=${loja}`, getHeaders())
		.then(response => {
			// Cache the response
			apiCache.campaigns[loja] = {
				data: response.data,
				timestamp: now
			};
			
			dispatch({ type: GET_CAMPAIGNS, payload: response.data });
			if (cb) return cb(response.data);
		})
		.catch(error => {
			errorHandling(error);
			if (cb) cb(null);
		});
	}
}