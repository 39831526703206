import { useState, useEffect, useRef } from 'react'
import moment from "moment"
import EmailEditorHtml from "./email-editor"
import Loading from '../../components/Loading'
import { Notification, notify } from '../../utils/notification';
import Modal from '../../components/Modal/official'

export default function Templates({ admin, templates, saveTemplate, refreshList, removeTemplate }) {

  const [editor, setEditor] = useState(false);
  const [templateSelected, setTemplateSelected] = useState({});

  const [notification, setNotification] = useState({});
  const [deleting, setDeleting] = useState({ active: false, templateId: null });
  const [limit, setLimit] = useState(10); // Default limit

  function closeEditor() {
    setEditor(false)
    setTemplateSelected({})
  }

  function createNewTemplate() {
    setEditor(true)
    setTemplateSelected({})
  }

  useEffect(() => {
    refreshList(limit)
    console.log("lista atualizada!")
  }, [templateSelected, limit])

  function handleRemove(templateId) {
    removeTemplate(templateId, admin.loja, (response) => {
      refreshList(limit);
      setDeleting({ active: false, templateId: null });
      return notify("Template excluído com sucesso!", setNotification);
    })
  }

  function loadMoreTemplates() {
    setLimit(prevLimit => prevLimit + 10);
  }

  return !templates ? <Loading /> : (
    <div className="templates">
      <Notification notification={notification} />
      <div className="section-actions">
        <button onClick={createNewTemplate}><i className="fas fa-plus"></i> Criar Novo</button>  
      </div>
      { templates.map((template, index) => (
        <div className="template" key={index}>
          <div className="information">
            <h1>{template.title}</h1>
            { template.createdAt === template.updatedAt ? (
              <p><span className="status createdAt">Adicionada em</span> {moment(template.createdAt).format("DD/MM/YYYY")} às {moment(template.createdAt).format("HH:mm")}</p>
            ) : (
              <p><span className="status updatedAt">Atualizada em</span> {moment(template.updatedAt).format("DD/MM/YYYY")} às {moment(template.updatedAt).format("HH:mm")}</p>
            )}
            <div className="actions">
              <button className="edit" onClick={() => { setTemplateSelected({ _id: template._id, title: template.title, design: template.design }); setEditor(true) }}>Editar Template</button>
              <button className="remove" onClick={() => setDeleting({ active: true, templateId: template._id })}>Excluir</button>
            </div>
          </div>
          <div className="preview">
            <div className="preview-html-overlay"></div>
            <div className="preview-html" dangerouslySetInnerHTML={{ __html: template.html}}></div>
          </div>
        </div>
      )) }

      {templates.length >= limit && (
        <div className="load-more-container">
          <button className="edit" onClick={loadMoreTemplates}>
            <i className="fas fa-sync-alt"></i> Carregar mais templates
          </button>
        </div>
      )}

      <Modal text="Deseja realmente excluir este template?" active={deleting.active ? true : false} close={() => setDeleting({ active: false, templateId: null })} confirm={() => handleRemove(deleting.templateId)} />

      <EmailEditorHtml active={editor} templateSelected={templateSelected} setTemplateSelected={setTemplateSelected} closeEditor={closeEditor} admin={admin} saveTemplate={saveTemplate} />

    </div>
  )
}